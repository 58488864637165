/* Mixins */
.design-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #cdcdcd;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.design-table .design-table-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 150px;
  width: 150px;
}
.design-table .design-table-column div {
  border-bottom: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 5px 0;
  height: 30px;
}
.design-table .design-table-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-x: scroll;
}
.design-table .design-table-content-wrapper .design-table-content-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.design-table .design-table-content-wrapper .design-table-content-container .design-table-content-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.design-table .design-table-content-wrapper .design-table-content-container .design-table-content-row .cell {
  width: 150px;
  min-width: 150px;
  border-bottom: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 5px 0;
  height: 30px;
}
.design-table .design-table-content-wrapper .design-table-content-container .design-table-content-row .cell-hover:hover {
  cursor: pointer;
  background: #cdcdcd;
}
.design-table .design-table-content-wrapper .design-table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.design-table .design-table-content-wrapper .design-table-row .cell {
  width: 150px;
  min-width: 150px;
  border-bottom: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
  padding: 5px 0;
  height: 30px;
}